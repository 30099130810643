import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Logo from '../components/Logo';
import OpeningSection from '../components/Home/Opening';
import AboutSection from '../components/Home/About';
import PortfolioSection from '../components/Home/Portfolio';
import ContactSection from '../components/Home/Contact';
// import InstagramSection from '../components/Home/InstagramSection';

const IndexPage = ({ data, location }) => {
  const {
    opening_image,
    secondary_image,
    portfolio_image,
    about_blurb,
    contact_blurb,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout background="#ecd9d4" hideLogo={true} location={location}>
      <SEO title="Home" />
      <LogoContainer>
        <Logo color="#FFFFFF" height="56" weight="2.75" />
      </LogoContainer>

      <OpeningSection image={opening_image} />
      <AboutSection text={about_blurb} image={secondary_image} />
      <PortfolioSection image={portfolio_image} />
      <ContactSection text={contact_blurb} />
      {/* <InstagramSection /> */}
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    markdownRemark(frontmatter: { title: { eq: "home" } }) {
      frontmatter {
        opening_image
        secondary_image
        portfolio_image
        about_blurb
        contact_blurb
      }
    }
  }
`;

const LogoContainer = styled.div`
  display: none;

  @media (min-width: 700px) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 5;
  }
`;
